var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[(_vm.blog.mainImage)?_c('jumbotron',{attrs:{"aspect-ratio":_vm.isOnPC ? 3 : 16 / 10,"src":_vm.blog.mainImage}},[_c('v-container',{staticClass:"fill-height pa-0 ma-0 white--text"},[_c('v-row',{staticClass:"fill-height",attrs:{"justify":"start","align":"end"}},[_c('v-col',{attrs:{"cols":12}},[_c('h1',{class:{
              'display-2': _vm.isOnPC,
              'display-1': !_vm.isOnPC,
              'px-4': _vm.isOnPC,
              'px-2': !_vm.isOnPC
            },staticStyle:{"text-shadow":"3px 3px 5px rgba(0, 0, 0, 0.7)"}},[_vm._v(_vm._s(_vm.blog.title))]),_c('p',{class:{
              'px-4': _vm.isOnPC,
              'px-2': !_vm.isOnPC
            },staticStyle:{"text-shadow":"3px 3px 5px rgba(0, 0, 0, 0.7)"}},[_c('small',[_vm._v(_vm._s(_vm.formattedDate))])])])],1)],1)],1):_c('h1',{class:{
      'display-2': _vm.isOnPC,
      'display-1': !_vm.isOnPC,
      'px-4': _vm.isOnPC,
      'px-2': !_vm.isOnPC
    }},[_vm._v(" "+_vm._s(_vm.blog.title)+" ")]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.isOnPC)?_c('article',{domProps:{"innerHTML":_vm._s(_vm.blog.content)}}):_c('article',{domProps:{"innerHTML":_vm._s(_vm.mobileHtml)}})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }