<template>
  <div class="d-flex flex-column">
    <jumbotron v-if="blog.mainImage" :aspect-ratio="isOnPC ? 3 : 16 / 10" :src="blog.mainImage">
      <v-container class="fill-height pa-0 ma-0 white--text">
        <v-row class="fill-height" justify="start" align="end">
          <v-col :cols="12">
            <h1
              :class="{
                'display-2': isOnPC,
                'display-1': !isOnPC,
                'px-4': isOnPC,
                'px-2': !isOnPC
              }"
              style="text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7)"
            >{{ blog.title }}</h1>
            <p
              :class="{
                'px-4': isOnPC,
                'px-2': !isOnPC
              }"
              style="text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7)"
            ><small>{{ formattedDate }}</small></p>
          </v-col>
        </v-row>
      </v-container>
    </jumbotron>
    <h1 v-else :class="{
        'display-2': isOnPC,
        'display-1': !isOnPC,
        'px-4': isOnPC,
        'px-2': !isOnPC
      }"
    >
      {{ blog.title }}
    </h1>
    <v-container>
      <v-row>
        <v-col cols="12">
          <article v-if="isOnPC" v-html="blog.content" />
          <article v-else v-html="mobileHtml" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import jumbotron from '../components/Jumbotron'
import firebase from '../plugins/firebase'
import moment from 'moment'

export default {
  props: {
    articleId: {
      type: String,
      required: true
    }
  },

  components: {
    jumbotron: jumbotron
  },

  data () {
    return {
      blog: {},
      loading: false
    }
  },

  computed: {
    mobileHtml () {
      const source = this.$data.blog.content
      const result = source.replace(
        /<img\s+.*?\/?>/gmi,
        x => x.replace(/style=".*?"/gmi, 'style="width: 100%"')
      )
      return result
    },
    formattedDate () {
      return moment(this.$data.blog.dateModified.toDate()).format('Do of MMMM, YYYY')
    },
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },

  async created () {
    this.$data.loading = true
    this.$data.blog = await this.getBlogData()
    if (this.$data.blog === null) this.$router.go('/NotFound')
    this.$data.loading = false
  },

  methods: {
    getBlogData () {
      const db = firebase.firestore()

      return new Promise((resolve, reject) => {
        db.collection('articles/').doc(this.articleId).get().then(doc => {
          if (!doc.exists) resolve(null)
          resolve(doc.data())
        })
      })
    }
  },

  watch: {
    blog: function (v) {
      document.title = `Article: ${v.title ? v.title : v.name} - AmborJo`
    }
  }
}
</script>
